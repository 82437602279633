import React from 'react'
import juan_van_der_riel from '../../assets/img/juan-van-der-riel@2x.jpg'
import dawid_le_roux from '../../assets/img/dawid-le-roux@2x.jpg'
import name_surname from '../../assets/img/name-surname@2x.jpg'
import { Card3 } from '../ui/Card3'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class OurTeamSection extends React.Component {

    state = {
        team: [
            {
                title: 'Juan Van Der Riel',
                text: 'CEO & Founder',
                image: juan_van_der_riel
            },
            {
                title: 'David Le Roux',
                text: 'Financial Advisor',
                image: dawid_le_roux
            },
            {
                title: 'Berté Le Roux',
                text: 'Financial Advisor',
                image: name_surname
            }
        ]
    }

    renderColumns = () => {
        return this.state.team.map((item, index) => {
            return (
                
                <div className="col-md-4" key={index}>
                    <OnScrollAnimation>
                        <Card3 
                            title={item.title}
                            text={item.text}
                            image={item.image}
                        />
                    </OnScrollAnimation>
                </div>
                                
            )
        })
    }
    render(){
        
        return(
            <section className="vdr_ourteam-section">
                <div className="container" style={{ marginBottom: '50px' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <OnScrollAnimation>
                                <h4 className="vdr_top-sub-heading">OUR TEAM MEMBERS</h4>
                                <h2>Discover Our Team And Experts</h2>
                                <div className="hor_line"></div>
                            </OnScrollAnimation>                            
                        </div>
                    </div>
                </div>
                <div className="container vdr_container">
                    <div className="row">
                        {this.renderColumns()}
                    </div>                    
                </div>
            </section>
        )
    }
}