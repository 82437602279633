import React from 'react'
import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom'

import ScrollToTop from './ScrollToTop'

//pages
import {
    Home,
    About,
    Solutions,
    Contact,
    GEPF,
    Wills,
    RetirementPlanning,
    EstatePlanning,
    BusinessAndKeyPersonRiskPlanning,
    InvestmentPlanning,
    RiskPlanning,
    PersonalAndBusinessInsurance,
    CreditLifeInsurance,
    FAQ,
    Thankyou,
    PageNotFound,
    PageNotFound404
} from './pages'

class App extends React.Component {
    render(){
        return(
            <BrowserRouter>
                <ScrollToTop />
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={Home}
                    />
                    <Route
                        exact
                        path="/about"
                        component={About}
                    />
                    <Route
                        exact
                        path="/solutions"
                        component={Solutions}
                    />
                    <Route
                        exact
                        path="/solutions/gepf"
                        component={GEPF}
                    />
                    <Route
                        exact
                        path="/solutions/wills"
                        component={Wills}
                    />
                    <Route
                        exact
                        path="/solutions/retirement-planning"
                        component={RetirementPlanning}
                    />
                    <Route
                        exact
                        path="/solutions/personal-and-business-insurance"
                        component={PersonalAndBusinessInsurance}
                    />
                    <Route
                        exact
                        path="/solutions/estate-planning"
                        component={EstatePlanning}
                    />
                    <Route
                        exact
                        path="/solutions/business-and-key-person-risk-planning"
                        component={BusinessAndKeyPersonRiskPlanning}
                    />
                    <Route
                        exact
                        path="/solutions/investment-planning"
                        component={InvestmentPlanning}
                    />
                    <Route
                        exact
                        path="/solutions/risk-planning"
                        component={RiskPlanning}
                    />
                    <Route
                        exact
                        path="/solutions/credit-life-insurance"
                        component={CreditLifeInsurance}
                    />
                    <Route
                        exact
                        path="/solutions/frequently-asked-questions"
                        component={FAQ}
                    />
                    
                    <Route
                        exact
                        path="/contact"
                        component={Contact}
                    />

                    <Route
                        exact
                        path="/thankyou"
                        component={Thankyou}
                    />

                    <Route
                        exact
                        path="/404"
                        component={PageNotFound404}
                    />

                    <Route
                        component={PageNotFound}
                    />
                    
                </Switch>
            </BrowserRouter>
        )
    }
}

export default App