import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/about-us-banner@2x.jpg'
import aboutJvdr from '../../assets/img/about-jvdr@2x.jpg'
import { 
    AboutJVRSection,
    WhyChooseUsSection,
    OurTeamSection,
    TestimonialSection,
} from '../Contents'
import { LetsStartAConversation } from '../Forms'

export class About extends React.Component {
    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="About" 
                    link="/about"
                    img={ bannerDesktop }
                />
                <AboutJVRSection image={aboutJvdr} />
                <WhyChooseUsSection />
                <OurTeamSection />
                <TestimonialSection />
                <LetsStartAConversation />
            </React.Fragment>
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org/",
            "@type": "Person",
            "name": "Juan Van Der Riel",
            "url": "https://vanderrielpractice.co.za/about",
            "image": "https://vanderrielpractice.co.za/static/media/about-jvdr.0b7abf38.jpg",
            "sameAs": [
              "https://www.facebook.com/Van-der-Riel-Practice-348680295747374/",
              "https://www.linkedin.com/in/juan-louis-van-der-riel-711108183/"
            ],
            "jobTitle": "CEO & Founder",
            "worksFor": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            }  
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | About Us</title>
                    <meta name="description" content="We are a dedicated and professional financial planning practice that offers an all inclusive financial planning approach for both individuals and businesses." />
                    <meta name="keywords" content="JUAN VAN DER RIEL, Van Der Riel, Associates General Practice, Financial, Adviser, Old Mutual, risk" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/about" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}