import React from 'react'
import { Logo, MenuIcon, MenuMobile, MenuWideScreen, TopBar } from './'
import { Footer, BottomBar } from '../Footer'

export class Nav extends React.Component {

    state = {
        menuVisible: false
    }

    toggleMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible
        })
    }

    render(){
        return(
            <>

                <TopBar />
                
                <header>
                    <div className="ui container">
                        <div className="vdr_header">
                            <div className="vdr_header-item">
                                <Logo />
                            </div>  
                            <div className="vdr_header-item">
                                <MenuIcon onClick={this.toggleMenu} state={this.state.menuVisible ? 'close' : 'open'} />
                                <MenuWideScreen />
                            </div> 
                        </div>
                        
                    </div>           
                </header>

                <MenuMobile state={this.state.menuVisible ? 'open' : 'closed'} />

                <div className="vdr_page-content">{this.props.children}</div>

                <Footer />

                <BottomBar />

            </>
        )
    }
}