import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading,
    ImageText
} from '../ui'
import retirement_planning_01 from '../../assets/img/retirement-planning-01@2x.jpg'
import retirement_planning_02 from '../../assets/img/retirement-planning-02@2x.jpg'
import { LetsStartAConversation } from '../Forms'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class RetirementPlanning extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Retirement Planning"
                    img={ bannerDesktop }
                />
                <Heading title="Retirement Planning">

                    <p>Retirement planning is essentially asking the ultimate question: “How much is enough for me to retire comfortably with?”. This is essentially dependent on how much after tax income (in today’s money) you will need when you retire. The date of your retirement is also very important as it gives you the amount of time you will need to save to get there.</p>

                    <p>There is also investment risk involved in the process. Using this information, you can usually get a reasonably accurate picture of what you need and how much you need to save every month in order to get there. The tricky part is also the different ways of saving for retirement and the investments you need use when that day eventually comes.</p>
                    
                </Heading>
                <ImageText image={retirement_planning_01} style={{marginTop: '20px'}}>

                    <p>Most people who work for an employer do have a retirement fund with them (either a provident or pension). They are therefore saving for their retirement every month as it is deducted from their salary. </p>
                    
                    <p>In recent years it has been found that your pension or provident fund is usually not enough in the end for you to comfortably retire with. Many people also make the big mistake of withdrawing their hard earned savings in cash from their retirement fund when they resign, are dismissed or retrenched. It is therefore important to get professional advice when this happens. </p>
                    
                    <p>People whose employer do not offer a retirement fund or are self-employed usually use retirement annuities (RAs) to save for retirement.</p>
                </ImageText>
                <ImageText image={retirement_planning_02} order="switch" style={{marginTop: '20px'}}>

                    <p>Given current legislation you are allowed to save up to 27.5% (or max R350,000) of your income (salary and other taxable incomes) every tax year into a retirement fund (via your employer’s retirement fund or retirement annuities or a combination). If you contribute more than this, the extra amount is taken over to the following tax years to be used if possible.</p>
                    
                    <p>Your retirement savings also do not necessarily have to be in a retirement fund only. Other savings and investments can be used to supplement it.</p>
                    
                    <p>It is therefore very important that you discuss your existing retirement savings and other investments as well as your ultimate retirement goal with a professional financial adviser.</p>
                </ImageText>
                <LetsStartAConversation retirePlanning />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/retirement-planning"
            },
            "headline": "Retirement Planning",
            "description": "Retirement planning is essentially asking the ultimate question: “How much is enough for me to retire comfortably with?”. This is essentially dependent on how much after tax income you will need when you retire. The date of your retirement is also very important as it gives you the amount of time you will need to save to get there.",
            "image": "https://vanderrielpractice.co.za" + retirement_planning_01,  
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": "https://vanderrielpractice.co.za" + imageObject
              }
            },
            "datePublished": "2020-9-30",
            "dateModified": "2020-9-30"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Retirement Planning</title>
                    <meta name="description" content="Retirement planning is essentially asking the ultimate question: “How much is enough for me to retire comfortably with?”. This is essentially dependent on how much after tax income you will need when you retire. The date of your retirement is also very important as it gives you the amount of time you will need to save to get there." />
                    <meta name="keywords" content="Retirement, retire, tax, income" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/retirement-planning" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}