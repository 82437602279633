import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading
} from '../ui'
import { LetsStartAConversation } from '../Forms/LetsStartAConversation'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class Wills extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Wills"
                    img={ bannerDesktop }
                />
                <Heading title="Wills">

                    <p>The last will and testament is the ultimate document that will determine what needs to happen with your assets, property, investments, personal belongings and even your pets when you pass away.</p>

                    <p>Dying without one places your estate into the ambit of the Intestate Succession Act and your assets, investments and personal belongings will have to be divided amongst specific beneficiaries based on your immediate family and even extended relatives.</p>

                    <p>Your bequests in your will also have significant tax and cost consequences for your estate. Therefore, wills also tend to form part and parcel of a well-designed estate plan.</p>
                    
                </Heading>
                <LetsStartAConversation wills />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/wills"
            },
            "headline": "Wills",
            "description": "The last will and testament is the ultimate document that will determine what needs to happen with your assets, property, investments, personal belongings and even your pets when you pass away.",
            "image": "https://vanderrielpractice.co.za" + bannerDesktop,  
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": "https://vanderrielpractice.co.za" + imageObject
              }
            },
            "datePublished": "2020-9-30",
            "dateModified": "2020-9-30"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Wills</title>
                    <meta name="description" content="The last will and testament is the ultimate document that will determine what needs to happen with your assets, property, investments, personal belongings and even your pets when you pass away." />
                    <meta name="keywords" content="Wills, assets, property, investments, belongings, pets" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/wills" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}