import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading,
    ImageText,
    Content
} from '../ui'
import investment_planning from '../../assets/img/investment-planning@2x.jpg'
import { LetsStartAConversation } from '../Forms'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class InvestmentPlanning extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Investment Planning"
                    img={ bannerDesktop }
                />
                <Heading title="Investment Planning">

                    <p>Investment planning usually refers to building up your wealth and saving enough money for specific goals at specific times.</p>
                    
                </Heading>
                <ImageText image={investment_planning}>
                    <h3 className="vdr_green-text">The usual investment goals are:</h3>
                    <ul>
                        <li>Saving for a deposit on a new home or investment property.</li>
                        <li>A deposit or full payment on a new car.</li>
                        <li>Weddings and other celebrations.</li>
                        <li>Legacies for children.</li>
                        <li>A holiday or well-deserved trip.</li>
                        <li>Emergency savings.</li>
                        <li>Supporting you in retirement.</li>
                    </ul>
                </ImageText>
                <Content>
                    <div className="col-md-12 text-center">
                        <p>There are many ways to invest, from direct deposits with the bank to trading in shares. Investments in rental or buy-improve-sell properties are also commonplace. The basic premise is for your money to work for you and earn you a profit and or returns on investment over a period of time. There are however always risks involved to investing (mostly in a loss of value of your investment at certain times or event), where the usual saying goes that: “The higher the risk, the greater the reward”.</p>
                    </div>
                    <div className="col-md-12 text-center" style={{margin: '50px 0'}}>
                        <h3 className="vdr_green-text">The 3 most common investment methods used by financial advisers are the following:</h3>
                    </div>
                    <div className="col-md-12">
                        <h3 className="vdr_green-text">Unit Trusts:</h3>
                        <p>Also known as LISPs (Linked Investment Service Providers). This investment is where you invest your savings in either a lump sum or a regular savings into an investment account. You then choose which investment funds you want to invest into. The funds have a specific investment mandate and associated risk. They can invest in shares (local and international), quoted property (usually known as Real Estate Investment Trusts), bonds (both company and government) as well as money market instruments (also known as cash instruments). There are currently over 400 funds available in the Republic of South Africa to choose from. Any dividends, interest or capital gains that you earn in the investment are taxed in your own hands.</p>
                        <h4>The usual advantages are:</h4>
                        <ul>
                            <li>You can easily add to and withdraw from your investment at any time.</li>
                            <li>You have a wide range of investment funds to choose from to fit your investment goal.</li>
                        </ul>
                        <h4>The usual disadvantages are:</h4>
                        <ul>
                            <li>You cannot nominate the savings in the investment to a beneficiary. The investment forms part of your estate.</li>
                        </ul>
                    </div>
                    <div className="col-md-12" style={{padding: '30px 0'}}>
                        <hr />
                    </div>
                    <div className="col-md-12">
                        <h3 className="vdr_green-text">Endowments:</h3>
                        <p>Also known as Life Wrapped Investments. The endowment fund works pretty much the same as a Unit Trust mentioned above. However, you are required to invest your savings for a minimum of 5 years where you can only withdraw from the savings once. After 5 years you are allowed to withdraw as much as you would like. Given regulations you are also not allowed to make large additional deposits into the investment without a new restriction being applied. The endowment allows for beneficiaries to be appointed for the savings and also has a fixed tax applied to all returns you earn on investment (30%).</p>
                        <h4>The usual advantages are:</h4>
                        <ul>
                            <li>Beneficiary nominations for your savings in event of your passing.</li>
                            <li>30% fixed tax on the investment, this is advantageous to higher income earners in the 30% + tax brackets.</li>
                        </ul>
                        <h4>The usual disadvantages are:</h4>
                        <ul>
                            <li>Limited access to the investment in the first 5 years.</li>
                            <li>Cannot make large additional deposits in the investment once it has been started without causing additional restrictions on your access to the investment.</li>
                        </ul>
                    </div>
                    <div className="col-md-12" style={{padding: '30px 0'}}>
                        <hr />
                    </div>
                    <div className="col-md-12">
                        <h3 className="vdr_green-text">Tax Free Savings:</h3>
                        <p>Also works exactly the same as the Unit Trust mentioned above. You are limited to investing only R36,000 per tax year into the investment (total monthly savings and deposits). You are also only allowed to invest R500,000 over your lifetime into the plan. These limits are penned by the RSA Treasury yearly and subject to change. All growth on your investment is free of tax given current regulations. You can withdraw some or all your savings from the contract at any time. Some (not all) companies allow you to nominate beneficiaries on your tax free savings.</p>
                        <h4>The usual advantages:</h4>
                        <ul>
                            <li>0% tax on your investment, your investment grows a lot better.</li>
                            <li>You can easily add (within yearly and lifetime limits) to and withdraw from your investment at any time.</li>
                        </ul>
                        <h4>The usual disadvantages:</h4>
                        <ul>
                            <li>The yearly and lifetime limit on what you can invest.</li>
                        </ul>
                    </div>                          
                </Content>
                <LetsStartAConversation investmentPlanning />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/investment-planning"
            },
            "headline": "Investment Planning",
            "description": "Investment planning usually refers to building up your wealth and saving enough money for specific goals at specific times.",
            "image": "https://vanderrielpractice.co.za" + investment_planning,  
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": "https://vanderrielpractice.co.za" + imageObject
              }
            },
            "datePublished": "2020-9-30",
            "dateModified": "2020-9-30"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Investment Planning</title>
                    <meta name="description" content="Investment planning usually refers to building up your wealth and saving enough money for specific goals at specific times." />
                    <meta name="keywords" content="Investment, wealth, goals" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/investment-planning" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}