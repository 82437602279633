import React from 'react'
import avatar from '../../assets/img/kyle-profile-pi.png'
import banner from '../../assets/img/testimonials-map@2x.png'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class TestimonialSection extends React.Component {

    state = {
        testimonials: [
            {
                title: 'KYLE DAVIES',
                text: '“It is such a pleasure to deal with Juan and his team. I know that he will always be there to answer any of my dumb questions when it comes to Financial Planning. It is great to work with someone who has your best interest at heart.”',
                subTitle: 'CEO & Founder of Optimal Online',
                image: avatar
            }
        ]
    }

    renderColumns = () => {
        return this.state.testimonials.map((item, index) => {
            return (
                <OnScrollAnimation key={index}>
                    <img src={item.image} alt={item.title} />
                    <p className="vdr_italic">{item.text}</p>
                    <h3 style={{marginTop: '30px'}}>{item.title}</h3>
                    <p>{item.subTitle}</p>
                </OnScrollAnimation>
            )
        })
    }

    render() {
        var settings = {
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: 'ease-in-out',
            arrows: false,
        };
        return (
            <section className="vdr_testimonial-section" style={{
                backgroundImage: `url(${banner})`
            }}>
                <div className="container vdr_slide">
                    <Slider {...settings}>
                        {this.renderColumns()}
                    </Slider>
                </div>
            </section>
        )
    }
}