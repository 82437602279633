import React from 'react'
import { Link } from 'react-router-dom'

export class Card4 extends React.Component {
    render(){
        return(
            <Link to={this.props.link}>
                <div className="card vdr_card-4">
                    <div className="vdr_img_cont">
                        <div className="vdr_green">
                            <img src={this.props.image} alt={this.props.title} />
                        </div>
                        <div className="vdr_white">
                            <img src={this.props.image2} alt={this.props.title} />
                        </div>
                    </div>
                    
                    
                    <h3>{this.props.title}</h3>
                    <p>{this.props.text}</p>
                    <div className="vdr_readmore-button">{this.props.linkText}</div>
                </div>
            </Link>
        )
    }
}