import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading,
    ImageText
} from '../ui'
import lifestyle_adjustment_assurance from '../../assets/img/lifestyle-adjustment-assurance@2x.jpg'
import life_insurance from '../../assets/img/life-insurance@2x.jpg'
import earning_ability_assurance from '../../assets/img/earning-ability-assurance@2x.jpg'
import { LetsStartAConversation } from '../Forms'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'


export class RiskPlanning extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Risk Planning"
                    img={ bannerDesktop }
                />
                <Heading title="Risk Planning">

                    <p>Risk planning is where you plan accordingly for things that are eventual (like death) or have a reasonable chance of happening (permanent or temporary disability, impairment, severe illness, retrenchment).</p>

                    <p>These events are both financially and emotionally traumatic. This is especially so when you have minor children that are dependent on you or you are the only breadwinner for the family.</p>

                    <p>When your employer has a pension or provident fund, they usually include some form of death-in-service and disability-in-service benefits. Employers also very rarely provide for severe illnesses that you are diagnosed with while you are working. It is therefore very important to go through these benefits with a professional and experienced financial adviser first to make very sure that you have enough cover in place to protect yourself and your family’s financial future.</p>
                    
                </Heading>
                <ImageText image={life_insurance}>
                    <h3 className="vdr_green-text">Life assurance:</h3>
                    <p>This type of risk protection usually provides a cash lump sum to your estate or your loved ones if you pass away due to natural or unnatural reasons. It is usually used to provide for the financial wellbeing of your family when you are no longer there to do so.</p>
                    <h4>Life assurance is used to:</h4>
                    <ul>
                        <li>Pay off final expenses in terms of your debts, estate and funeral costs (the average basic funeral in our times comes to R30,000 to R50,000).</li>
                        <li>Provide for the maintenance and wellbeing of your spouse and minor children (your own or adopted).</li>
                        <li>Provide for the future education of your children, especially when you have to plan for college and university costs.</li>
                        <li>Provide for your responsibilities towards any ex-spouses in terms of a maintenance order from a divorce agreement.</li>
                    </ul>
                </ImageText>
                <ImageText image={earning_ability_assurance} order="switch">
                    <h3 className="vdr_green-text">Earning Ability Assurance:</h3>
                    <p>This type of risk protection usually provides a cash lump sum or a regular monthly income amount to you if you become temporarily (only income benefits) or permanently occupationally disabled due to accident/illness. Your greatest asset is after all your ability to earn an income for yourself and your family. You also receive protection if you become permanently functionally impaired (permanently lose your ability or have extreme difficulty to see, hear, taste, write, walk, use the bathroom/bathe etc.).</p>
                    <p>You can also protect yourself against retrenchment by securing a monthly income at least for 6 months while you are looking for a new job.</p>                      
                </ImageText>
                <ImageText image={lifestyle_adjustment_assurance}>
                    <h3 className="vdr_green-text">Lifestyle Adjustment Assurance:</h3>
                    <p>This type of risk protection usually provides for a cash lump sum in event that you are diagnosed with a recognised severe illness. The lump sum is usually based on the severity of the disease/condition. The four major severe illnesses/conditions are usually heart attacks, strokes, cancer and heart bypass surgery.</p>
                    <p>Such an event usually causes havoc on your finances as the medical aid only covers your in-hospital costs, but not the extra costs outside of hospital. There are also protections available against the permanent physical impairment of limbs and vital functions (eating, walking, writing, hearing, seeing etc).</p>

                    <h4>Lifestyle Adjustment Cover is used to:</h4>
                    <ul>
                        <li>Provide for an emergency reserve for you and your family in case of future setbacks.</li>
                        <li>Help you adjust your home accordingly, especially in event of wheelchair access or special equipment being needed.</li>
                        <li>Pay off some or all of your credit cards, short term loans, vehicle finance or even your home loan. This helps with reducing your monthly expenses while you are going for treatment and your ability to work is possibly affected.</li>
                    </ul>                      
                </ImageText>
                <LetsStartAConversation riskPlanning />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/risk-planning"
            },
            "headline": "Risk Planning",
            "description": "Risk planning is where you plan accordingly for things that are eventual (like death) or have a reasonable chance of happening (permanent or temporary disability, impairment, severe illness, retrenchment).",
            "image": "https://vanderrielpractice.co.za" + lifestyle_adjustment_assurance,  
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": "https://vanderrielpractice.co.za" + imageObject
              }
            },
            "datePublished": "2020-9-30",
            "dateModified": "2020-9-30"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Risk Planning</title>
                    <meta name="description" content="Risk planning is where you plan accordingly for things that are eventual (like death) or have a reasonable chance of happening (permanent or temporary disability, impairment, severe illness, retrenchment)." />
                    <meta name="keywords" content="Risk, death, disability, impairment, illness, retrenchment" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/risk-planning" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}