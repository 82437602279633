import React from 'react'
import { Link } from 'react-router-dom'

export class Card extends React.Component {
    render(){
        return(
            <Link to={this.props.link}>
                <div className="vdr_card card">
                    <h4>{this.props.title}</h4>
                    <div className="vdr_card-img-container">
                        <img className="card-img-top" src={this.props.image} alt={this.props.title} />
                    </div>                
                    <p>{this.props.text}</p>
                    <div className="vdr_readmore-button">{this.props.linkText}</div>
                </div>
            </Link>
        )
    }
}