import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import {Link} from 'react-router-dom'

export class Thankyou extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>       
                
                <section className="vdr_getintouch-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2>Thank You</h2>
                                <div className="hor_line" style={{margin:'0 auto 30px auto'}}></div>
                                Thank you for your enquiry. A consultant will be in touch with you shortly.<br /><br />
                                <Link to="/" className="vdr_theme-button">Explore</Link>                            
                            </div>
                        </div>
                    </div>
                </section>
                
            </React.Fragment>            
        )
    }
    
    render(){
        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Thank You</title>
                    <meta name="description" content="Thank you for your enquiry. A consultant will be in touch with you shortly." />
                    <meta name="robots" content="noindex, nofollow" />
                    {/* Event snippet for Website sale conversion page */}
                    <script> 
                        {`
                            gtag('event', 'conversion', { 'send_to': 'AW-401922072/UbfNCKKk__sBEJiw078B', 'transaction_id': '' })
                        `}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}