import React from 'react'
import faq from '../../assets/img/faq@2x.jpg'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax';

export class FAQSection extends React.Component {
    render(){
        return(
            <Parallax
                bgImage={faq}
                bgImageAlt="Van Der Riel Answered Questions"
                strength={400}
            >            
                <section className="vdr_faq-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2>Frequently Asked Questions</h2>
                                <div className="hor_line"></div>
                                <Link className="vdr_theme-button" to="/solutions/frequently-asked-questions">VIEW FAQ’s</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </Parallax>
        )
    }
}