import React from 'react'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class Heading extends React.Component {

    render() {
        return (
            <OnScrollAnimation>
                <section className="vdr_heading-section">
                    <div className="container">
                        <div clssname="row">
                            <div className="col-md-12">
                                <h1>{this.props.title}</h1>
                                <div className="hor_line"></div>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </section>
            </OnScrollAnimation>
            
        )
    }
}