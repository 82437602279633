import React from 'react'

import icon_1_green from '../../assets/img/Icons/01-green.svg'
import icon_1_white from '../../assets/img/Icons/01-white.svg'
import icon_2_green from '../../assets/img/Icons/02-green.svg'
import icon_2_white from '../../assets/img/Icons/02-white.svg'
import icon_3_green from '../../assets/img/Icons/03-green.svg'
import icon_3_white from '../../assets/img/Icons/03-white.svg'
import icon_4_green from '../../assets/img/Icons/04-green.svg'
import icon_4_white from '../../assets/img/Icons/04-white.svg'
import icon_5_green from '../../assets/img/Icons/05-green.svg'
import icon_5_white from '../../assets/img/Icons/05-white.svg'
import icon_6_green from '../../assets/img/Icons/06-green.svg'
import icon_6_white from '../../assets/img/Icons/06-white.svg'
import icon_7_green from '../../assets/img/Icons/07-green.svg'
import icon_7_white from '../../assets/img/Icons/07-white.svg'
import icon_8_green from '../../assets/img/Icons/08-green.svg'
import icon_8_white from '../../assets/img/Icons/08-white.svg'
import icon_9_green from '../../assets/img/Icons/09-green.svg'
import icon_9_white from '../../assets/img/Icons/09-white.svg'

import { Card4 } from '../ui/Card4'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class SolutionsSection extends React.Component {

    state = {
        solutions: [
            {
                title: 'Government Employee Pension Funds',
                image: icon_1_green,
                image2: icon_1_white,
                text: 'South African Government Employees receive significant benefits from the GEPF. Understanding these benefits…',
                link: '/solutions/gepf',
                linkText: 'Read More'
            },
            {
                title: 'Wills',
                image: icon_2_green,
                image2: icon_2_white,
                text: 'Passing away without a will leaves your hard earned belonings and assets open to the standard intestate succession law of South Africa…',
                link: '/solutions/wills',
                linkText: 'Read More'
            },
            {
                title: 'Retirement Planning',
                image: icon_3_green,
                image2: icon_3_white,
                text: 'Investing and saving wisely so that you have enough to provide a comfortable income for yourself and your spouse for the rest of your…',
                link: '/solutions/retirement-planning',
                linkText: 'Read More'
            },
            {
                title: 'Investment Planning:',
                image: icon_4_green,
                image2: icon_4_white,
                text: 'Investment planning usually refers to building up your wealth and saving enough money for specific goals at specific times.',
                link: '/solutions/investment-planning',
                linkText: 'Read More'
            },
            {
                title: 'Personal and Business Insurance',
                image: icon_5_green,
                image2: icon_5_white,
                text: 'You need comprehensive insurance for your personal and business assets to protect against unexpected…',
                link: '/solutions/personal-and-business-insurance',
                linkText: 'Read More'
            },
            {
                title: 'Estate Planning',
                image: icon_6_green,
                image2: icon_6_white,
                text: 'Ensuring your estate has enough provisions to cover all expenses in event of death as well as provide for your wishes and bequests. This also…',
                link: '/solutions/estate-planning',
                linkText: 'Read More'
            },
            {
                title: 'Business and Key-person Risk Planning',
                image: icon_7_green,
                image2: icon_7_white,
                text: 'Ensuring that your business can still continue if one of the key person’s or partners pass away, become severely…',
                link: '/solutions/business-and-key-person-risk-planning',
                linkText: 'Read More'
            },
            {
                title: 'Risk Planning',
                image: icon_8_green,
                image2: icon_8_white,
                text: 'Risk planning is where you plan accordingly for things that are eventual (like death) or have a reasonable chance of happening.',
                link: '/solutions/risk-planning',
                linkText: 'Read More'
            },
            {
                title: 'Credit Life Insurance ',
                image: icon_9_green,
                image2: icon_9_white,
                text: 'Whether it’s a personal loan, a revolving loan, a credit card or a student loan, you are probably paying for credit life insurance…',
                link: '/solutions/credit-life-insurance',
                linkText: 'Read More'
            }
        ]
    }

    renderColumns = () => {
        return this.state.solutions.map((item, index) => {
            return (
                <div className="col-md-6 col-lg-4" key={index}>
                    <OnScrollAnimation>
                        <Card4 
                            title={item.title}
                            image={item.image}
                            image2={item.image2}
                            text={item.text}
                            link={item.link}
                            linkText={item.linkText}
                        />
                    </OnScrollAnimation>
                </div>
            )
        })
    }

    render(){
        return(
            <section 
                className="vdr_solutions-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <OnScrollAnimation>
                                <h4 className="vdr_top-sub-heading">OUR SOLUTIONS</h4>
                                <h2>What We Are Offering To Our Clients</h2>
                                <div className="hor_line"></div>
                            </OnScrollAnimation>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        {this.renderColumns()}
                    </div>
                </div>
            </section>
        )
    }
}