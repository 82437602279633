import React from 'react'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'
import axios from 'axios'
import { Popup } from '../ui'
import Recaptcha from 'react-recaptcha';

export class LetsStartAConversation extends React.Component{

    state = {
        formValues: {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        },
        submitSuccess: false,
        submitFailed: false,
        isVerified: false
    }

    componentDidMount(){
        this.setState({
            formValues: {...this.state.formValues, subject: this.renderSubject()}
        })
    }

    renderSubject = () => {
        if(this.props.gepf){ return 'Government Employees Pension Fund (GEPF)' }
        if(this.props.wills){ return 'Wills' }
        if(this.props.riskPlanning){ return 'Risk Planning' }
        if(this.props.retirePlanning){ return 'Retirement Planning' }
        if(this.props.personalAndBusinessInsurance){ return 'Personal and Business Insurance' }
        if(this.props.investmentPlanning){ return 'Investment Planning' }
        if(this.props.estatePlanning){ return 'Estate Planning' }
        if(this.props.businessAndKeyPersonRiskPlanning){ return 'Business and Key-person Risk Planning' }
        if(this.props.creditLifeInsurance){ return 'Credit Life Insurance' }
        return ''
    }

    onNameChange = event => {
        this.setState({
            formValues: {...this.state.formValues, name: event.target.value}
        })
    }
    onEmailChange = event => {
        this.setState({
            formValues: {...this.state.formValues, email: event.target.value}
        })
    }
    onPhoneChange = event => {
        this.setState({
            formValues: {...this.state.formValues, phone: event.target.value}
        })
    }
    onSubjectChange = event => {
        this.setState({
            formValues: {...this.state.formValues, subject: event.target.value}
        })
    }
    onMessageChange = event => {
        this.setState({
            formValues: {...this.state.formValues, message: event.target.value}
        })
    }

    recaptchaLoaded = () => {
        console.log('capcha successfully loaded');
    }

    verifyCallback = response => {
        if (response) {
            this.setState({
                isVerified: true
            })
            console.log('capcha successfully verified');
        }
    }

    onSubmit = event => {
        event.preventDefault()
        console.log(this.state.formValues)
        if (this.state.isVerified){
            axios({
            method: "POST", 
            url:"https://admin.vanderrielpractice.co.za/api/mail/contact",
            data:  this.state.formValues
            }).then((response)=>{
                
                if (response.status === 200){
                    this.setState({
                        submitSuccess:true
                    })
                    this.resetForm()
                } else {
                    this.setState({
                        submitFailed:true
                    })
                }
            })
        } else{
            alert('Please verify recapcha')
        }
        
    }

    resetForm(){    
        this.setState({
            formValues: {
                ...this.state.formValues, 
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            }
        })
    }

    onClosePopup = () => {
        window.location.href="/"
    }
    onClosePopupFailed = () => {
        this.setState({
            submitFailed: false
        })
    }

    onChange = (value) => {
        console.log("Captcha value:", value)
    }

    render(){
        if(this.state.submitSuccess){
            return window.location.href = '/thankyou'
        }

        const {name, email, phone, subject, message} = this.state.formValues
        
        return(
            <section className="vdr_lets-start-a-conversation-form">
                <OnScrollAnimation>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Let’s Start The Conversation.</h2>
                                <div className="hor_line"></div>
                            </div>
                        </div>
                        <form 
                            className="row vdr_form" 
                            onSubmit={this.onSubmit}>  
                                                  
                            <div className="col-md-6">
                                <input 
                                    name="name" 
                                    className="vdr_input" 
                                    type="text" 
                                    placeholder="Your Name" 
                                    value={name}
                                    onChange={this.onNameChange}
                                    required />
                            </div>     
                            <div className="col-md-6">
                                <input 
                                    name="email" 
                                    className="vdr_input" 
                                    type="email" 
                                    placeholder="Your Email"
                                    value={email} 
                                    onChange={this.onEmailChange}
                                    required />
                            </div> 
                            <div className="col-md-6">
                                <input 
                                    name="phone" 
                                    className="vdr_input" 
                                    type="text" 
                                    placeholder="Your Phone"
                                    value={phone} 
                                    onChange={this.onPhoneChange}
                                    required />
                            </div> 
                            <div className="col-md-6">
                                <select 
                                    name="subject" 
                                    className="vdr_input" 
                                    placeholder="Subject"
                                    onChange={this.onSubjectChange} 
                                    value={subject}
                                    required
                                >
                                    <option value="">Select Subject</option>
                                    <option>Wills</option>
                                    <option>Risk Planning</option>
                                    <option>Retirement Planning</option>
                                    <option>Government Employees Pension Fund (GEPF)</option>
                                    <option>Personal and Business Insurance</option>
                                    <option>Investment Planning</option>
                                    <option>Estate Planning</option>
                                    <option>Business and Key-person Risk Planning</option>
                                    <option>Credit Life Insurance</option>
                                    <option>Other</option>
                                </select>
                            </div> 
                            <div className="col-md-12">
                                <textarea 
                                    name="message" 
                                    className="vdr_input" 
                                    placeholder="Message" 
                                    value={message}
                                    onChange={this.onMessageChange}
                                    required
                                    rows="3"></textarea>
                            </div>  
                            <div className="col-md-12 text-center" style={{
                                display:'flex',
                                justifyContent: 'center',
                                padding: '0px 0 30px 0'
                            }}>
                                <Recaptcha
                                    sitekey="6LdFVqMZAAAAAHNhdx7TgqlZ0NaBwGnvcdTM_lzu"
                                    render="explicit"
                                    onloadCallback={this.recaptchaLoaded}
                                    verifyCallback={this.verifyCallback}
                                />
                            </div>                             
                            <div className="col-md-12 text-center">
                                <input className="vdr_theme-button" type="submit" value="Submit" />
                            </div>            
                        </form>
                    </div>
                </OnScrollAnimation>

                { this.state.submitFailed &&
                    (
                        <Popup>
                            <h2>We're sorry, Your message couldn't be send.</h2>
                            <div className="hor_line"></div>
                            Please email us at <a href="mailto:jvanderrie@oldmutual.com">jvanderrie@oldmutual.com</a>.<br /><br />
                            <button className="vdr_theme-button" onClick={this.onClosePopupFailed}>Close</button>
                        </Popup>
                    )
                }
                
            </section>
        )
    }
}