import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading,
    Content
} from '../ui'
import { LetsStartAConversation } from '../Forms'

export class FAQ extends React.Component {

    state = {
        faq: [
            {
                q: 'How often will I receive my income?',
                a: ['You do not have access to any further savings from your investment other than the income. You can only ask for a full ‘cash-in’ of your investment if the value falls under R50,000. This is subject to taxation.']
            },
            {
                q: 'How much income can I receive from the product?',
                a: [
                    'Legislation allows you to select a level of income between 2.5% and 17.5% of your portfolio value. This is an advantage if you want a high income over a shorter period or if you want to lower your taxable income.','However, it is important to note that if your income level is higher than the annual growth of your portfolio it will impact and erode your retirement capital, and therefore your future income.'
                ]
            },
            {
                q: 'What are the risks of investing in a living annuity?',
                a: [
                    'In a living annuity you are taking on the risk of using up all your money before you die. It is important to remain disciplined about the level of income you withdraw.',
                    'You need to be aware of the factors that influence your level of income in order to be able to manage this after you retire.'
                ]
            },
            {
                q: 'Don’t think this is necessary as it has been handled in the risk section.',
                a: [
                    'What are the risks of investing in a living annuity? In a living annuity you are taking on the risk of using up all your money before you die. It is important to remain disciplined about the level of income you withdraw. You need to be aware of the factors that influence your level of income in order to be able to manage this after you retire'
                ]
            },
            {
                q: 'What happens if I die?',
                a: [
                    'The death benefit from a living annuity is paid out to your nominated beneficiary(ies). The beneficiary(ies) can then choose to take the balance as a cash lump sum (taxable) or transfer it into a living annuity in their own name or a combination of both.'
                ]
            }
        ]
    }

    renderAnswers = (a) => {
        return a.map((item, index) => {
            return <p key={index}>{item}</p>
        })
    }

    renderQuestions = () => {
        return this.state.faq.map((item, index) => {
            return (
                <div className="col-md-12">
                    <h4>{item.q}</h4>
                    {this.renderAnswers(item.a)}
                    <br />
                    <hr />
                    <br /><br />
                </div>
            )
        })
    }

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="FAQs"
                    img={ bannerDesktop }
                />
                <Heading title="Do You Have Any Questions?">
                    <p>Here are some more frequently asked questions and answers regarding living annuities. It is however of cardinal importance to have a full discussion with a financial professional in order to cover all the facets, including the advantages and disadvantages of a living annuity.</p> 
                </Heading>
                <Content style={{marginTop: '50px'}}>
                    {this.renderQuestions()}
                </Content>

                <LetsStartAConversation />

            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "How often will I receive my income?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You do not have access to any further savings from your investment other than the income. You can only ask for a full ‘cash-in’ of your investment if the value falls under R50,000. This is subject to taxation."
              }
            },{
              "@type": "Question",
              "name": "How much income can I receive from the product?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Legislation allows you to select a level of income between 2.5% and 17.5% of your portfolio value. This is an advantage if you want a high income over a shorter period or if you want to lower your taxable income. However, it is important to note that if your income level is higher than the annual growth of your portfolio it will impact and erode your retirement capital, and therefore your future income."
              }
            },{
              "@type": "Question",
              "name": "What are the risks of investing in a living annuity?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "In a living annuity you are taking on the risk of using up all your money before you die. It is important to remain disciplined about the level of income you withdraw. You need to be aware of the factors that influence your level of income in order to be able to manage this after you retire."
              }
            },{
              "@type": "Question",
              "name": "Don’t think this is necessary as it has been handled in the risk section.",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "What are the risks of investing in a living annuity? In a living annuity you are taking on the risk of using up all your money before you die. It is important to remain disciplined about the level of income you withdraw. You need to be aware of the factors that influence your level of income in order to be able to manage this after you retire."
              }
            },{
              "@type": "Question",
              "name": "What happens if I die?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The death benefit from a living annuity is paid out to your nominated beneficiary(ies). The beneficiary(ies) can then choose to take the balance as a cash lump sum (taxable) or transfer it into a living annuity in their own name or a combination of both."
              }
            }]
          }
        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Frequently Asked Questions</title>
                    <meta name="description" content="Do You Have Any Questions? Here are some more frequently asked questions and answers regarding living annuities. It is however of cardinal importance to have a full discussion with a financial professional in order to cover all the facets, including the advantages and disadvantages of a living annuity." />
                    <meta name="keywords" content="Questions, FAQ, answers, living, annuities, financial, annuity" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/frequently-asked-questions" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}