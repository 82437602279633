import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading,
    ImageText,
    Content
} from '../ui'
import estate_planning from '../../assets/img/estate-planning@2x.jpg'
import { LetsStartAConversation } from '../Forms'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class EstatePlanning extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Estate Planning"
                    img={ bannerDesktop }
                />
                <Heading title="Estate Planning">

                    <p>Estate Planning is essentially making sure that your investments, property and other assets are correctly set up in order to go to the correct persons, pay the minimal possible taxes and costs in event of your death. This also includes taking a look at Trusts. Estate Planning also gives you a reasonably accurate idea of what it would cost you to die and whether you have enough cash in your estate to pay those costs.</p>

                    <p>If you do not have the necessary provisions in place, the estate may then be forced to sell your hard earned investments, properties and assets in order to cover them. Leaving your loved ones in a very bad financial and emotional state.</p>
                    
                </Heading>
                <ImageText image={estate_planning} style={{marginTop: '40px'}}>
                    <h3 className="vdr_green-text">Just a few costs that people usually do not think of when thinking of estates:</h3>

                    <ul>
                        <li>Conveyancing and property transfer costs.</li>
                        <li>Masters Fees (High Court of South Africa).</li>
                        <li>Estate advertising fees.</li>
                        <li>Valuation costs.</li>
                        <li>Outstanding taxes owed to SARS.</li>
                        <li>Responsibilities towards ex-spouses and children from previous marriages in terms of a Divorce Agreement.</li>
                    </ul>
                </ImageText>
                <Content>
                    <div className="col-md-12 text-center">
                        <p>It is therefore very important to have a conversation with a professional financial adviser surrounding estate planning. This helps you ensure that your wishes in terms of your will are actually cost effective and able to be carried out.</p>
                    </div>
                </Content>
                <LetsStartAConversation estatePlanning />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/estate-planning"
            },
            "headline": "Estate Planning",
            "description": "Estate Planning is essentially making sure that your investments, property and other assets are correctly set up in order to go to the correct persons, pay the minimal possible taxes and costs in event of your death. This also includes taking a look at Trusts. Estate Planning also gives you a reasonably accurate idea of what it would cost you to die and whether you have enough cash in your estate to pay those costs.",
            "image": "https://vanderrielpractice.co.za" + estate_planning,  
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": "https://vanderrielpractice.co.za" + imageObject
              }
            },
            "datePublished": "2020-9-30",
            "dateModified": "2020-9-30"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Estate Planning</title>
                    <meta name="description" content="Estate Planning is essentially making sure that your investments, property and other assets are correctly set up in order to go to the correct persons, pay the minimal possible taxes and costs in event of your death. This also includes taking a look at Trusts. Estate Planning also gives you a reasonably accurate idea of what it would cost you to die and whether you have enough cash in your estate to pay those costs." />
                    <meta name="keywords" content="Estate, investments, property, assets, death, Trusts, costs" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/estate-planning" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}