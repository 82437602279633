import React from 'react'
import juanSignature from '../../assets/img/juan-signature.svg'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'
import {Bullet} from '../ui'

export class AboutJVRSection extends React.Component {
    render(){
        return(
            <section className="vdr_about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            <OnScrollAnimation>
                                <h4 className="vdr_top-sub-heading">ABOUT JUAN VAN DER RIEL</h4>
                                <h2>Proud Principle Of The Van Der Riel And Associates General Practice</h2>
                                <p>Juan-Louis is a Senior Financial Adviser with Old Mutual. He has almost a decade of experience in the industry with expert knowledge in risk planning for both individuals and businesses.</p>
                            </OnScrollAnimation>
                            <OnScrollAnimation>
                                <ul>
                                    <li>
                                        <Bullet />Regional Top 25 Adviser Old Mutual Limited.
                                    </li>
                                    <li>
                                        <Bullet />Top Risk and Estate Planning Agent to with Old Mutual Limited.
                                    </li>
                                    <li>
                                        <Bullet />Expert knowledge of the Government Employees Pension Fund (GEPF) relating to risk and retirement planning.
                                    </li>
                                    <li>
                                        <Bullet />Backed by a driven group of expert Financial Advisers, Legal Consultants and Administrative Staff.
                                    </li>

                                </ul>
                                <hr />
                            </OnScrollAnimation>
                            <OnScrollAnimation>
                                <div className="container">
                                    <div className="row vdr_signature-row">
                                        <div className="col-lg-4 col-md-12 vdr_grid">
                                            <img src={juanSignature} alt="Juan Van Der Riel Signature" className="vdr_signature" />
                                        </div>
                                        <div className="col-lg-4 col-md-12 vdr_grid">
                                            <h4>JUAN VAN DER RIEL</h4>
                                            <small>CEO AND FOUNDER</small>
                                        </div>
                                        <div className="col-lg-4 col-md-12 border-left vdr_grid">
                                            <h4>074 143 3870</h4>
                                            <small>Call to ask any question</small>
                                        </div>
                                    </div>
                                </div>
                            </OnScrollAnimation>
                        </div>
                        <div className="col-md-6 col-lg-5">
                            <OnScrollAnimation>
                                <img src={this.props.image} alt="Proud Principle Of The Van Der Riel And Associates General Practice" />
                            </OnScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}