import React from 'react'
import { Link } from 'react-router-dom'

export class Breadcrums extends React.Component {
    render(){
        return(
            <p>
                <Link to="/">Home</Link>&nbsp;&nbsp;| 
                &nbsp;<Link to={this.props.link ? this.props.link : '#'}>{this.props.title}</Link>
                {this.props.subtitle ? ` | ${this.props.subtitle}` : ''}
            </p>
        )
    }
}