import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/contact-us-banner@2x.jpg'
import { 
    LetsStartAConversation 
} from '../Forms'
import GoogleMapLocation from '../Contents/GoogleMapLocation'
import banner from '../../assets/img/testimonials-map@2x.jpg'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class Contact extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Contact Us" 
                    link="/contact" 
                    img={ bannerDesktop }
                />        
                <section className="vdr_googlemaps-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 vdr_col">
                                <OnScrollAnimation>
                                    <GoogleMapLocation />
                                </OnScrollAnimation>
                            </div>
                        </div>
                    </div>    
                </section>
                <section className="vdr_getintouch-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <OnScrollAnimation>
                                    <h4 className="vdr_top-sub-heading">GET IN TOUCH</h4>
                                    <h2>We’re Happy to Answer any Question You Have?</h2>
                                    <div className="hor_line"></div>
                                </OnScrollAnimation>
                                <OnScrollAnimation>
                                    <h3>Address:</h3>
                                    <p>54 Oxford Street, Durbanville</p>
                                    <h3>Email:</h3>
                                    <p>jvanderrielpa3@oldmutual.com</p>
                                    <h3>Contact:</h3>
                                    <p>074 143 3870</p>
                                </OnScrollAnimation>                                
                            </div>
                            <div className="col-md-6 vdr_lets-start-column" style={{
                                backgroundImage: `url(${banner})`,
                                backgroundColor: '#52A145'
                            }}>
                                <LetsStartAConversation />                                
                            </div>
                        </div>
                    </div>
                </section>
                
                
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": {
              "name": "http://schema.org/name",
              "description": "http://schema.org/description",
              "image": {
                "@id": "http://schema.org/image",
                "@type": "@id"
              },
              "geo": "http://schema.org/geo",
              "latitude": {
                "@id": "http://schema.org/latitude",
                "@type": "xsd:float"
              },
              "longitude": {
                "@id": "http://schema.org/longitude",
                "@type": "xsd:float"
              },
              "xsd": "http://www.w3.org/2001/XMLSchema#"
            },
            "name": "Van Der Riel And Associates General Practice",
            "description": "Juan-Louis is a Senior Financial Adviser with Old Mutual. He has almost a decade of experience in the industry with expert knowledge in risk planning for both individuals and businesses.",
            "image": "https://vanderrielpractice.co.za/static/media/about-jvdr@2x.35362f7a.jpg",
            "geo": {
              "latitude": "-33.982334",
              "longitude": "18.598267"
            }
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Contact Us</title>
                    <meta name="description" content="We’re Happy to Answer any Question You Have. We are a dedicated and professional financial planning practice that offers an all inclusive financial planning approach for both individuals and businesses." />
                    <meta name="keywords" content="Question, financial, businesses" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/contact" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}