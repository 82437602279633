import React from 'react'
import phoneIcon from '../../assets/img/phone-call.svg'
import mailIcon from '../../assets/img/mail.svg'
import clockIcon from '../../assets/img/clock.svg'
import liIcon from '../../assets/img/linkedin-logo-button.svg'
import fbIcon from '../../assets/img/facebook-logo-button.svg'

export class TopBar extends React.Component {
    render(){
        return(
            <div className="vdr_topbar">
                <div className="ui container">
                    <div className="vdr_topbar-inner">
                        <div className="vdr_topbar-left">
                            <small>
                                <img src={clockIcon} alt="Clock" />
                                Open Hours: Mon - Fri 9:00 - 17:00                    
                            </small>
                        </div>
                        <div className="vdr_topbar-right">
                            <small>
                                <img src={mailIcon} alt="Email" />
                                <a href="mailto:jvanderrielpa3@oldmutual.com">jvanderrielpa3@oldmutual.com</a>
                            </small>
                            <small>
                                <img src={phoneIcon} alt="Phone" />
                                <a href="tel:074 143 3870">074 143 3870</a>
                            </small>
                            <small style={{borderLeft:'1px solid #ffffff'}}>
                                <a href="https://www.facebook.com/Van-der-Riel-Practice-348680295747374/" target="_blank" rel="noopener noreferrer">
                                    <img src={fbIcon} alt="Facebook" />
                                </a>
                                <a href="https://www.linkedin.com/in/juan-louis-van-der-riel-711108183/" target="_blank" rel="noopener noreferrer">
                                    <img src={liIcon} alt="Linkedin" style={{marginRight: '0'}} />
                                </a>                                
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}