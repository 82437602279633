import React from 'react'
import ReactDOM from 'react-dom'

export class Popup extends React.Component {

    render() {
        return(
            ReactDOM.createPortal(
                <div className="vdr_popup-wrapper">
                    <div className="vdr_popup-inner text-center">
                        {this.props.children}
                    </div>                    
                </div>,
                document.querySelector("#popup")
            )
            
        )      
    }
}