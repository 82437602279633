import React from 'react'
import { Card2 } from '../ui/Card2'
import parallaxScrollImage from '../../assets/img/parallax-scroll.jpg'
import { Parallax } from 'react-parallax';
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

import icon_1_green from '../../assets/img/Icons/01-green.svg'
import icon_2_green from '../../assets/img/Icons/02-green.svg'
import icon_3_green from '../../assets/img/Icons/03-green.svg'
import icon_4_green from '../../assets/img/Icons/04-green.svg'
import icon_5_green from '../../assets/img/Icons/05-green.svg'
import icon_6_green from '../../assets/img/Icons/06-green.svg'
import { Link } from 'react-router-dom'

export class OurSolutionsSection extends React.Component {

    state = {
        solutions: [
            {
                title: 'Government Employee Pension Funds',
                image: icon_1_green,
                text: 'South African Government Employees receive significant benefits from the GEPF. Understanding these benefits…',
                link: '/solutions/gepf',
                linkText: 'Read More'
            },
            {
                title: 'Wills',
                image: icon_2_green,
                text: 'Passing away without a will leaves your hard earned belonings and assets open to the standard intestate succession law of South Africa…',
                link: '/solutions/wills',
                linkText: 'Read More'
            },
            {
                title: 'Retirement Planning',
                image: icon_3_green,
                text: 'Investing and saving wisely so that you have enough to provide a comfortable income for yourself and your spouse for the rest of your…',
                link: '/solutions/retirement-planning',
                linkText: 'Read More'
            },
            {
                title: 'Personal and Business Insurance',
                image: icon_4_green,
                text: 'You need comprehensive insurance for your personal and business assets to protect against unexpected…',
                link: '/solutions/personal-and-business-insurance',
                linkText: 'Read More'
            },
            {
                title: 'Estate Planning',
                image: icon_5_green,
                text: 'Ensuring your estate has enough provisions to cover all expenses in event of death as well as provide for your wishes and bequests. This also…',
                link: '/solutions/estate-planning',
                linkText: 'Read More'
            },
            {
                title: 'Business and Key-person Risk Planning',
                image: icon_6_green,
                text: 'Ensuring that your business can still continue if one of the key person’s or partners pass away, become severely…',
                link: '/solutions/business-and-key-person-risk-planning',
                linkText: 'Read More'
            }
        ]
    }

    renderColumns = () => {
        return this.state.solutions.map((item, index) => {
            return (
                <div className="col-md-6 col-lg-4" key={index}>
                    <OnScrollAnimation>
                        <Card2 
                            title={item.title}
                            image={item.image}
                            text={item.text}
                            link={item.link}
                            linkText={item.linkText}
                        />
                    </OnScrollAnimation>                    
                </div>
            )
        })
    }

    render(){
        return(
            <Parallax
                bgImage={parallaxScrollImage}
                bgImageAlt="Van Der Riel Solutions"
                strength={400}
            >
                <section className="vdr_our-solutions-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <OnScrollAnimation>
                                    <h4 className="vdr_top-sub-heading">OUR SOLUTIONS</h4>
                                    <h2>We provide a holistic financial planning service that provides for all your major financial needs. We also assist in reviewing your financial plan on regular basis to ensure that you stay on track to reach your goals.</h2>
                                    <div className="hor_line"></div>
                                </OnScrollAnimation>                                
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            {this.renderColumns()}
                        </div>
                        <div className="row vdr_row">
                            <div className="col-md-12 text-center" style={{marginTop: '40px'}}>
                                <Link to="/solutions" className="vdr_theme-button">All Solutions</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </Parallax>
            
        )
    }
}