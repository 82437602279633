import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import {Link} from 'react-router-dom'

export class PageNotFound404 extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>       
                
                <section className="vdr_getintouch-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>Page Not Found</h1>
                                <div className="hor_line" style={{margin:'0 auto 30px auto'}}></div>
                                The page you are requesting can not be found.<br /><br />
                                <Link to="/" className="vdr_theme-button">Explore</Link>                            
                            </div>
                        </div>
                    </div>
                </section>
                
            </React.Fragment>            
        )
    }
    
    render(){
        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | 404 Page Not Found</title>
                    <meta name="description" content="The page you are requesting can not be found." />
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}