import React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

class GoogleMapLocation extends React.Component{
    render(){
        const style = {
            width: '100%',
            height: '400px'        
        }
        return(            
            <Map 
                google={this.props.google} 
                zoom={14}
                initialCenter={{
                lat: -33.827552,
                lng: 18.644481
                }}
                style={style}
                onClick={this.onMapClicked}
            >
                <Marker
                    title={'The marker`s title will appear as a tooltip.'}
                    name={'5JCV+XQ Durbanville, Cape Town'}
                    position={{lat: -33.827552, lng: 18.644481}} />
            </Map>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyDZ9HsWjdnCUf_3_ASESBpK7gVIVyX4g2U')
})(GoogleMapLocation);