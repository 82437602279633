import React from 'react'
import { Link } from 'react-router-dom'

export class MenuMobile extends React.Component {

    getCurrentYear = () => {
        const d = new Date();
        return d.getFullYear();
    }
    
    render(){
        return(
            <nav className={`vdr_menu_mobile ${this.props.state}`}>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/solutions">Solutions</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>   
                
                <small className="vdr_copyright-smalltext">
                Copyright ©{this.getCurrentYear()} Van <strong>Der Riel Practice</strong><br /> All rights reserved | Created by <strong><a href="https://www.optimalonline.co.za/" target="_blank" rel="noopener noreferrer">OPTIMAL ONLINE PTY</a></strong>    
                </small>             
            </nav>
        )
    }
}