import React from 'react'
import { NavLink  } from 'react-router-dom'

export class MenuWideScreen extends React.Component {
    render(){
        return(
            <nav>
                <ul className="vdr_menu_widescreen">
                    <li>
                        <NavLink  
                            exact
                            activeClassName="vdr_active"
                            to="/"
                        >Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                            exact
                            activeClassName="vdr_active"
                            to="/about"
                        >About
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                            exact
                            activeClassName="vdr_active"
                            to="/solutions"
                        >Solutions
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                            exact
                            activeClassName="vdr_active"
                            to="/contact"
                        >Contact
                        </NavLink>
                    </li>  
                </ul>
            </nav>
        )
    }
}