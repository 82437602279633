import React from 'react'
import ourVision from '../../assets/img/our-vision.svg'
import ourMission from '../../assets/img/our-mission.svg'
import ourStrategies from '../../assets/img/our-strategies.svg'
import why_choose_us_parallax from '../../assets/img/why-choose-us-parallax-scroll@2x.jpg'
import { Parallax } from 'react-parallax';
import { OnScrollAnimation } from '../Animations'

export class WhyChooseUsSection extends React.Component {

    state = {
        data: [
            {
                title: "Our Vision",
                image: ourVision,
                text: "We believe that we can be the most trusted and suitable partner in the financial planning journey of our clients."
            },
            {
                title: "Our Mission",
                image: ourMission,
                text: "Through following a thorough and holistic financial planning process which takes into consideration and prioritises all our clients’ financial needs and goals. The process is also regualry reviewed. We want to ensure that we go the extra mile for our clients."
            },
            {
                title: "Our Strategies",
                image: ourStrategies,
                text: "We focus on risk, investment and retirement planning to ensure that we can provide for any financial need or goal that our clients may have."
            }
        ]
    }

    renderColumns = () => {
        return this.state.data.map((item, index) => {
            return (
                <OnScrollAnimation key={index}>
                    <div className="row vdr_row-wcu-item">
                        <div className="col-md-6 col-lg-4 vdr_col-left">
                            <div className="vdr_row vdr_inner">
                                <div className="vdr_item">
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="vdr_item">
                                    <h3>{item.title}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 vdr_col-right">
                            <div className="vdr_inner">
                                <p>{item.text}</p>
                            </div>                            
                        </div>
                    </div>
                </OnScrollAnimation>                    
            )
        })
    }

    render(){
        return (
            <Parallax
                bgImage={why_choose_us_parallax}
                bgImageAlt="Why Choose Van Der Riel"
                strength={400}>
                <section className="vdr_whychooseus-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <OnScrollAnimation>
                                    <h4 className="vdr_top-sub-heading">WHY CHOOSE US</h4>
                                    <h2>Building a trusted and lifelong partnership with our clients to ensure that their goals are achieved and their interests protected at all times.</h2>
                                    <div className="hor_line"></div>
                                </OnScrollAnimation>
                               
                            </div>
                        </div>

                        {this.renderColumns()}
                    </div>
                </section>
            </Parallax>
        )
    }
}