import React from 'react'
import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll';

export class OnScrollAnimation extends React.Component {
    render(){
        return(
            <ScrollAnimation 
                animateIn="animate__fadeIn" 
                animateOnce={true}
                duration={0.75}
            >
                {this.props.children}
            </ScrollAnimation>
        )
    }
}