import React from 'react'
import { Card } from './'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class CardContainer extends React.Component {

    renderColumns () {
        return (
            <React.Fragment>
                {
                    this.props.data.map((item, index) => {
                        return (
                            <div className="col-md-6 col-lg-4" key={index}>
                                <OnScrollAnimation>
                                    <Card
                                        title={item.title}
                                        image={item.image}
                                        text={item.text}
                                        link={item.link}
                                        linkText={item.linkText}
                                    />
                                </OnScrollAnimation>                                
                            </div>
                        )
                    })
                }
                
            </React.Fragment>
        )
    }
    render(){
        return(
            <div className=" vdr_container container">
                <div className="row">
                    {this.renderColumns()}
                </div>
            </div>
        )
    }
}