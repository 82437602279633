import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading,
    ImageText,
    Content
} from '../ui'
import assets_insure from '../../assets/img/assets-insure@2x.jpg'
import { LetsStartAConversation } from '../Forms'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class PersonalAndBusinessInsurance extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Personal and Business Insurance"
                    img={ bannerDesktop }
                />
                <Heading title="Personal and Business Insurance">

                    <p>Risk planning usually does not just include protecting yourself and your loved ones against the unlikely or the inevitable. Your personal assets can also be protected against damage or loss caused by accident, nature or other people. Therefore, when such an event does occur you can rest easy knowing that your insurance will either partially or fully cover this loss for you.</p>
                    
                </Heading>
                <ImageText image={assets_insure} style={{marginTop: '0'}}>
                    <h3 className="vdr_green-text">The assets that people tend to insure</h3>
                    <ul>
                        <li>Homes and other properties</li>
                        <li>Personal belongings and normal home contents (furniture)</li>
                        <li>High cost valuables (jewellery, coins, art collections, golf clubs, cycling equipment, high cost appliances and tools to name a few).</li>
                        <li>Cars, Motorcycles, Small Pleasure Craft/Boats</li>
                    </ul>
                </ImageText>
                <Content>
                    <div className="col-md-12 text-center">
                        <p>You can also insure your business’ assets against damage or loss. This can also include financial costs arising from having to close your business temporarily due to damage or losses. You also have the ability to insure against claims from people visiting and becoming injured on your business’ premises, for poor workmanship of products and against services rendered to clients or the public. </p>
                        <p>Insurance usually has optional or compulsory excesses and there are usually small prints involved that can affect how much you can claim and under what circumstances. </p>
                        <p>It is therefore imperative that you carefully read and understand what you are insured for and for how much, as being over-insured costs money while being under-insured can cause you to get out a lot less in event of a claim against your insurance. </p>
                        <p>Ideally you should have an in-depth conversation with a professional financial adviser to assist you in setting up your insurance for yourself and/or your business in a cost and cover effective manner.</p>
                    </div>
                </Content>
                <LetsStartAConversation personalAndBusinessInsurance />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/personal-and-business-insurance"
            },
            "headline": "Personal and Business Insurance",
            "description": "Risk planning usually does not just include protecting yourself and your loved ones against the unlikely or the inevitable. Your personal assets can also be protected against damage or loss caused by accident, nature or other people. Therefore, when such an event does occur you can rest easy knowing that your insurance will either partially or fully cover this loss for you.",
            "image": "https://vanderrielpractice.co.za" + assets_insure,  
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": "https://vanderrielpractice.co.za" + imageObject
              }
            },
            "datePublished": "2020-9-30",
            "dateModified": "2020-9-30"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Personal and Business Insurance</title>
                    <meta name="description" content="Risk planning usually does not just include protecting yourself and your loved ones against the unlikely or the inevitable. Your personal assets can also be protected against damage or loss caused by accident, nature or other people. Therefore, when such an event does occur you can rest easy knowing that your insurance will either partially or fully cover this loss for you." />
                    <meta name="keywords" content="Risk, protecting, protected, damage, loss, accident, insurance" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/personal-and-business-insurance" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}