import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/vdr-logo.svg'

export const Logo = () => {
    return(
        <Link to="/" className="vdr_logo">
            <img src={logo} alt="Van Der Riel Practice" />
        </Link>
    )
}