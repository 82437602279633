import React from 'react'
import { Breadcrums } from './'

export class Banner extends React.Component {
    render(){
        return(
            <section className="vdr_banner" style={{backgroundImage: `url(${this.props.img})`}}>
                <div className="container vdr_banner-container">
                    <div className="row vdr_inner">
                        <div className="col-md-12">
                            <h1 style={{ textTransform: 'uppercase' }}>{this.props.title}</h1>
                            <Breadcrums 
                                title={this.props.title} 
                                link={this.props.link ? this.props.link : null}
                                subtitle={this.props.subtitle ? this.props.subtitle : null} 
                            />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}