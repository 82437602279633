import React from 'react'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class ImageText extends React.Component {

    render() {
        return (
            <section className={`vdr_image-text-section ${this.props.order ? 'switch' : ''}`} style={this.props.style}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 vdr_col-left">
                            <OnScrollAnimation>
                                <figure>
                                    <img src={this.props.image} alt={this.props.alt} />
                                </figure>
                            </OnScrollAnimation>
                            
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <OnScrollAnimation>
                                <article>
                                    {this.props.children}
                                </article>
                            </OnScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}