import React from 'react'
import { Parallax } from 'react-parallax';
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class BGImageText extends React.Component {

    render() {
        return (
            <Parallax
                bgImage={this.props.image}
                bgImageAlt="Van Der Riel Solutions"
                strength={400}
            >          
                <OnScrollAnimation>
                    <section className="vdr_bg-image-text-section">
                        <div className="container">
                            <div className="row">
                                {
                                    this.props.title && (
                                        <div className="col-md-12 text-center">
                                            <h2>{this.props.title}</h2>
                                            <div className="hor_line"></div>
                                        </div>
                                    )
                                }
                                {this.props.children}
                            </div>
                        </div>
                    </section>
                </OnScrollAnimation>                
            </Parallax>
        )
    }
}