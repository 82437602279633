import React from 'react'
import ReactDOM from 'react-dom'

export class MenuIcon extends React.Component {

    render(){
        return(
            ReactDOM.createPortal(
                <button onClick={this.props.onClick} className={`bc_btn-nav vdr_menuIcon ${ this.props.state }`}>
                    <span></span><span></span><span></span>
                </button>,
                document.querySelector("#menuIcon")
            )
        )
    }    
}