import React from 'react'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class Content extends React.Component {

    render() {
        return (
            <OnScrollAnimation>
                <section className={`vdr_content-section ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                {
                                    this.props.title && (
                                        <h2>{this.props.title}</h2>
                                    )
                                }
                            </div>
                            {this.props.children}
                        </div>
                    </div>
                </section>
            </OnScrollAnimation>
        )
    }
}