import React from 'react'
import {
    Redirect
} from 'react-router-dom'

export class PageNotFound extends React.Component {
    
    render(){
        return(
            <Redirect to="/404" />
        )
    }
}