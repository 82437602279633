import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import resignation from '../../assets/img/resignation@2x.jpg'
import parallax from '../../assets/img/parallax-scroll@2x.jpg'
import otherOptions from '../../assets/img/other-options@2x.jpg'
import livingAnnuity from '../../assets/img/living-annuity@2x.jpg'
import {
    Heading,
    Content,
    ImageText,
    BGImageText,
    Bullet
} from '../ui'
import {
    FAQSection
} from '../Contents/FAQSection'
import { LetsStartAConversation } from '../Forms'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class GEPF extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="GEPF"
                    img={ bannerDesktop }
                />
                <Heading title="Government Employees Pension Fund (GEPF)">

                    <p>The GEPF is the largest pension fund in Africa and one of the largest in the world. The fund is valued at over R1.8 trillion. The fund has more or less 1.2 million active members as well as 450,000 pensioners/beneficiaries.</p>

                    <p>It is a defined benefit fund, therefore unlike most other employer retirement funds your benefits are calculated according to the number of years of service, your last average 24 months of salary and the branch of service you are in (armed forces or civil service).</p>
                    
                </Heading>

                <Content title="Benefits for Members" className="vdr_widescreen">
                    <div className="col-md-6">
                        <h3 className="vdr_green-text">Less than 10 years service:</h3>
                        <p><strong>Death of Member:</strong></p>
                        <ul>
                            <li>Spouse will receive a death in service gratuity lump sum only Funeral Benefits for Member (R15,000), Spouse (R15,000) and eligible Children (R6,000).</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <h3 className="vdr_green-text">More than 10 years of service:</h3>
                        <p><strong>Death of Member:</strong></p>
                        <ul>
                            <li>Spouse will receive a death in service gratuity lump sum and a widow/widower’s annuity (monthly income) for the rest of their lives.</li>
                            <li>Funeral Benefits for Member (R15,000), Spouse (R15,000) and eligible Children (R6,000).</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Permanent Disability or Severe Illness:</strong></p>
                        <ul>
                            <li>Disability in service gratuity only.</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Permanent Disability or Severe Illness:</strong></p>
                        <ul>                        
                            <li>Disability or Illness in service gratuity and annuity for the rest of member’s life.</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Medical Aid is subsidized by RSA Government</strong></p>
                        <p>Very limited benefits to members/family in first 10 years of service. It is important to therefore make sure you have enough life, disability and severe illness in place to cover the shortfalls.</p>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Medical Aid is subsidized by RSA Government.</strong></p>
                        <p>Benefits start to improve after 10 years of service, but the size of the benefits fully depend on how long you have been employed with Government. At 10 years of service you will usually receive benefits only in line with 40% of your current salary. It is therefore still important to make sure you have enough life, disability and severe illness cover in place to make up for any shortfalls.</p>
                    </div>
                </Content>


                

                <Content title="Benefits for Members" className="vdr_mobile">
                    <div className="col-md-12">
                        <p className="text-center">The benefits paid depend on whether a member has less than 10 years’ pensionable service, or 10 or more years of pensionable service.</p>
                        <br /><br />
                    </div>
                    <div className="col-md-6">
                        <h3 className="vdr_green-text">Less than 10 years service:</h3>
                        <p><strong>Death of Member:</strong></p>
                        <ul>
                            <li>Spouse will receive a death in service gratuity lump sum only Funeral Benefits for Member (R15,000), Spouse (R15,000) and eligible Children (R6,000).</li>
                        </ul>
                        <p><strong>Permanent Disability or Severe Illness:</strong></p>
                        <ul>
                            <li>Disability in service gratuity only.</li>
                        </ul>
                        <p><strong>Medical Aid is subsidized by RSA Government</strong></p>
                        <p>Very limited benefits to members/family in first 10 years of service. It is important to therefore make sure you have enough life, disability and severe illness in place to cover the shortfalls.</p>
                    </div>
                    <div className="col-md-6">
                        <h3 className="vdr_green-text">More than 10 years of service:</h3>
                        <p><strong>Death of Member:</strong></p>
                        <ul>
                            <li>Spouse will receive a death in service gratuity lump sum and a widow/widower’s annuity (monthly income) for the rest of their lives.</li>
                            <li>Funeral Benefits for Member (R15,000), Spouse (R15,000) and eligible Children (R6,000).</li>
                        </ul>
                        <p><strong>Permanent Disability or Severe Illness:</strong></p>
                        <ul>                        
                            <li>Disability or Illness in service gratuity and annuity for the rest of member’s life.</li>
                        </ul>
                        <p><strong>Medical Aid is subsidized by RSA Government.</strong></p>
                        <p>Benefits start to improve after 10 years of service, but the size of the benefits fully depend on how long you have been employed with Government. At 10 years of service you will usually receive benefits only in line with 40% of your current salary. It is therefore still important to make sure you have enough life, disability and severe illness cover in place to make up for any shortfalls.</p>
                    </div>
                </Content>

                <ImageText image={resignation}>
                    <h3 className="vdr_green-text">Resignation / Dismissal / Retrenchment before retirement.</h3>

                    <p><strong>If you resign, are dismissed or are retenched from service you have the following options available:</strong></p>
                    <ul>
                        <li>Withdraw your benefits in cash. The cash lump sum is taxed and only the first R22,500 is free of tax.</li>
                        <li>Preserve the ‘actuarial’ value of your benefit by transferring it tax free to either a pension preservation fund, a retirement annuity or a combination of both.</li>
                    </ul>
                    <p>It is therefore very important to first think through the consequences of resignation / dismissal / retrenchment and discuss the options avaialble to you with a financial planning professional.</p>
                </ImageText>

                <Content>
                    <div className="col-md-12">
                        <h3 className="vdr_green-text">Retire via the GEPF (age 60 to 65 depending on department):</h3>
                        <ul>
                            <li>You receive a retirement cash lump sum (taxable) and a monthly pension that is based on years of service, average last 24 months salary and branch of service.</li>
                            <li>Your spouse receives between 50% (default) or 75% (optional - reduced lump sum and pension) of your income in event of your death.</li>
                            <li>If you pass away within the first 5 years after retirement, your beneficiaries will receive a balance of payment cash lump sum.</li>
                            <li>You, your spouse and eligible children still receive the funeral benefits of the fund.</li>
                            <li>Children’s pension - until age 22 ????? (Juan to confirm)</li>
                            <li>Your medical aid contributions are subsidized via the GEPF.</li>
                        </ul>
                        <p><strong>Advantages:</strong></p>
                        <ul>
                            <li>Guaranteed pension</li>
                            <li>Medical aid subsidy</li>
                        </ul>
                        <p><strong>Disadvantages:</strong></p>
                        <ul>
                            <li>Cash lump sum less than the usual 1/3rd of your actual savings in fund.</li>
                            <li>Spouse only gets 50% of pension as a default, you have to reduce your pension and cash lump sum to increase this to 75%.</li>
                            <li>Very little benefit for your children. No legacy to your children if you and your spouse pass away.</li>
                        </ul>
                    </div>
                </Content>

                <Content>
                    <div className="col-md-12">
                        <h3 className="vdr_green-text">Retire via external provider (after age 55):</h3>
                        <p>Unfortunately you cannot retire externally with the GEPF. In order to retire outside of the GEPF you will first have to:</p>
                        <ul>
                            <li>1. Resign prior to retirement.</li>
                            <li>2. Transfer your value of your pension to a preservation fund or retirement annuity.</li>
                            <li>3. Retire from your preservation fund or retirement annuity. You can withdraw up to 1/3rd in cash from your preserved investment at retirement.</li>
                        </ul>
                        <p>The remaining savings need to be invested into income investments to provide you with an income after retirement.</p>
                    </div>
                </Content>

                <BGImageText 
                    title="Usually via a Living Annuity:"
                    image={parallax}
                >
                    <div className="col-md-12 text-center">
                        <p>Investment where you control how much income you want to take from your retirement savings (you can only invest retirement savings into the plan). Can be invested into any investment strategy you want to choose, from very conservative to very aggressive. In event of your death you can nominate the savings to go to beneficiaries of your choosing.</p>
                    </div>
                    <div className="col-md-6">
                         <h3 className="vdr_green-text">Advantages:</h3>
                         <ul>
                            <li>You control what income you want from the savings.</li>
                            <li>If managed correctly, you can increase your retirement savings and income over time.</li>
                            <li>You can nominate beneficiaries for the savings in event of death, thus you can leave a legacy.</li>
                         </ul>
                    </div>
                    <div className="col-md-6">
                         <h3 className="vdr_green-text">Disadvantages:</h3>
                         <ul>
                            <li>Your medical aid with GEMS ends and is no longer subsidized. You therefore need to take out a new medical aid.</li>
                            <li>You can outlive your savings if you draw too much income from it.</li>
                            <li>You do not have access to your savings in the investment.</li>
                         </ul>
                    </div>

                </BGImageText>

                <ImageText image={otherOptions} style={{marginTop: '40px'}}>
                    <h3 className="vdr_green-text">What Other Options Exist For You?</h3>

                    <p>A living annuity is designed to provide you with income during retirement. You can purchase the annuity with the benefits from your pension fund. It is a flexible way to grow your retirement capital and to draw income from your capital during your retirement.</p>
                </ImageText>

                <ImageText image={livingAnnuity} style={{marginTop: '0'}} order="switch">
                    <h3 className="vdr_green-text">What Is A Living Annuity?</h3>

                    <p>A living annuity is suitable for individuals who retire from a pension, provident, preservation or retirement annuity fund. A living annuity gives you more control over how your retirement fund is managed. It is an investment plan with the intention of growing your money during your retirement years based on a carefully selected investment strategy.</p>
                </ImageText>

                <Content title="Why Choose A Living Annuity:"  style={{marginBottom: '30px'}}>
                    <div className="col-md-12 text-center">
                        <div className="hor_line"></div>
                    </div>
                    <div className="col-md-6 vdr_no-text-decoration">
                        <ul>
                            <li className="vdr_flex-start mb-2"><Bullet /><span>You want a retirement fund to have the potential to continue to grow.</span></li>
                            <li className="vdr_flex-start mb-2"><Bullet /><span>You want more control over how your fund is invested.</span></li>
                        </ul>
                    </div>
                    <div className="col-md-6 vdr_no-text-decoration">
                        <ul>
                            <li className="vdr_flex-start mb-2"><Bullet /><span>You want to pass on the balance of your fund after your death.</span></li>
                            <li className="vdr_flex-start mb-2"><Bullet /><span>You want flexibility in the level of income you receive.</span></li>
                        </ul>
                    </div>
                </Content>

                <FAQSection />

                <LetsStartAConversation gepf />
                
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "ImageObject",
                    "@id": "https://vanderrielpractice.co.za/solutions/gepf/#primaryImage",
                    "url": "https://vanderrielpractice.co.za/static/media/living-annuity@2x.e96688db.jpg",
                    "width": 940,
                    "height": 700
                },
                {
                    "@type": "WebPage",
                    "@id": "https://vanderrielpractice.co.za/solutions/gepf/#webpage",
                    "url": "https://vanderrielpractice.co.za/solutions/gepf/",
                    "name": "Government Employees Pension Fund | Van Der Riel Practice",
                    "datePublished": "2020-9-14T17:09:05+02:00",
                    "dateModified": "2020-9-14T17:09:05+02:00",
                    "isPartOf": {
                        "@id": "https://vanderrielpractice.co.za/#website"
                    },
                    "primaryImageOfPage": {
                        "@id": "https://vanderrielpractice.co.za/solutions/gepf/#primaryImage"
                    },
                    "inLanguage": "en-ZA"
                },
                {
                    "@type": "Article",
                    "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": "https://vanderrielpractice.co.za/solutions/gepf"
                    },
                    "headline": "Government Employees Pension Fund",
                    "description": "The GEPF is the largest pension fund in Africa and one of the largest in the world. The fund is valued at over R1.8 trillion. The fund has more or less 1.2 million active members as well as 450,000 pensioners/beneficiaries.",
                    "image": "https://vanderrielpractice.co.za" + resignation,  
                    "author": {
                    "@type": "Organization",
                    "name": "Van Der Riel Practice"
                    },  
                    "publisher": {
                    "@type": "Organization",
                    "name": "Optimal Online",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://vanderrielpractice.co.za" + imageObject
                    }
                    },
                    "datePublished": "2020-9-30",
                    "dateModified": "2020-9-30"
                }
            ]
        }


        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Government Employees Pension Fund</title>
                    <meta name="description" content="The GEPF is the largest pension fund in Africa and one of the largest in the world. The fund is valued at over R1.8 trillion. The fund has more or less 1.2 million active members as well as 450,000 pensioners/beneficiaries." />
                    <meta name="keywords" content="pension, fund, pensioners, beneficiaries" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/gepf" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}