import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
import {
    SolutionsSection
} from '../Contents'
import {
    LetsStartAConversation
} from '../Forms'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'

export class Solutions extends React.Component {
    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions" 
                    img={ bannerDesktop }
                />
                <SolutionsSection />
                <LetsStartAConversation />
            </React.Fragment>
            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Van Der Riel Practice",
            "url": "https://vanderrielpractice.co.za"
        }
        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Solutions</title>
                    <meta name="description" content="Government Employee Pension Funds, Wills, Retirement Planning, Investment Planning, Personal and Business Insurance, Estate Planning, Business and Key-person Risk Planning, Risk Planning" />
                    <meta name="keywords" content="Government, Employee, Pension, Funds, Wills, Retirement, Investment, Business, Insurance, Estate, Key-person, Risk" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}