import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//temp placeholder images
import mobileheroSliderGepf from '../../assets/img/hero-slider-gepf-mobile@2x.jpg'
// import mobileheroSliderCalculator from '../../assets/img/hero-slider-calculator-mobile@2x.jpg'
import heroSliderGepf from '../../assets/img/hero-slider-gepf@2x.jpg'
// import heroSliderCalculator from '../../assets/img/hero-slider-calculator@2x.jpg'
import arrowPrev from '../../assets/img/iconmonstr-angel-left-thin.svg'
import arrowNext from '../../assets/img/iconmonstr-angel-right-thin.svg'

const NextArrow = props => {
    const { onClick } = props;
    return (
        <div
            className="vdr_arrow-next"
            onClick={onClick}
        >
            <img src={arrowNext} alt="" />
        </div>
        
    );
  }

const PrevArrow = props => {
    const { onClick } = props;
    return (
        <div
            className="vdr_arrow-prev"
            onClick={onClick}
        >
            <img src={arrowPrev} alt="" />
        </div>
    );
  }
 
export class HomePageSlider extends React.Component {

    state = {
        slides: [
            {
                imgDesktop: heroSliderGepf,
                imgMobile: mobileheroSliderGepf,
                title: 'Planning To Retire Or Resign At The GEPF?',
                link: '/solutions/gepf',
                linkText: 'ENQUIRE NOW'
            },
            // {
            //     imgDesktop: heroSliderCalculator,
            //     imgMobile: mobileheroSliderCalculator,
            //     title: 'If you have a homeloan or personal loan with the bank…',
            //     subTitle: 'Credit life assurance calculator?',
            //     link: '/solutions/gepf',
            //     linkText: 'ENQUIRE NOW'
            // }
        ]
    }

    renderSlides = () => {
        return this.state.slides.map((item, index) => {
            return (
                <div className="vdr_slider-item" key={index}>
                    <img src={ window.innerWidth > 768 ? item.imgDesktop : item.imgMobile } alt={item.title} />
                    <article className="vdr_slide-article">
                        <h4 className="vdr_top-sub-heading">{item.subTitle}</h4>
                        <h1>{item.title}</h1>
                        <Link to={item.link} className="vdr_theme-button">{item.linkText}</Link>
                    </article>                            
                </div>
            )
        })
    }

    render() {
        var settings = {
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            className: "vdr_homepage-slider",
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            dots: false,
            cssEase: 'ease-in-out',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
            ]
        };

        return (
            <React.Fragment>
                <Slider {...settings}>
                    {this.renderSlides()}
                </Slider>
            </React.Fragment>
        );
    }
}