import React from 'react'
import green_bullet from '../../assets/img/green-bullet.svg'

export class Bullet extends React.Component{
    render(){
        return(
            <span style={{marginRight: '10px'}}>
                <img src={green_bullet} alt="" style={{width: '20px'}} />
            </span>
        )
    }
}