import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { HomePageSlider } from '../Banners'
import { CardContainer } from '../ui'
import lifeCover from '../../assets/img/life-cover@2x.jpg'
import earningAbilityCover from '../../assets/img/earning-ability-cover@2x.jpg'
import lifestyleAdjustmentCover from '../../assets/img/lifestyle-adjustment-cover@2x.jpg'
import aboutJvdr from '../../assets/img/about-jvdr.jpg'
import { 
    AboutJVRSection, 
    OurSolutionsSection,
    OurTeamSection,
    TestimonialSection
} from '../Contents'
import { LetsStartAConversation } from '../Forms'
import { Link } from 'react-router-dom'

export class Home extends React.Component {

    state = {
        dataCards: [
            {
                title: 'Life Cover',
                image: lifeCover,
                text: 'Cover provision that pays out a cash lump sum when you pass away due to natural or unnatural reasons. Can be used to settle debt, cover estate costs…',
                link: '/solutions/risk-planning',
                linkText: 'Read More'
            },
            {
                title: 'LIFESTYLE ADJUSTMENT COVER',
                image: lifestyleAdjustmentCover,
                text: 'A severe illness can impact you and your family both emotionally and financially, which is why it pays to plan for the future with severe illness cover.',
                link: '/solutions/risk-planning',
                linkText: 'Read More'
            },
            {
                title: 'EARNING ABILITY COVER',
                image: earningAbilityCover,
                text: 'Protect your greatest asset - Your ability to earn an income. Disability and income protection insurance protects you and your family if you…',
                link: '/solutions/risk-planning',
                linkText: 'Read More'
            }
        ]
    }

    renderContent = () => {
        return (
            <React.Fragment>
                <HomePageSlider />
                <section className="vdr_under-banner-section">
                    <CardContainer data={this.state.dataCards} />
                </section>
                <div className="container text-center vdr_small-text-container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Don’t hesitate, contact us for better help and services. <Link className="vdr_theme-link" to="/solutions">Explore all services</Link></p>
                        </div>
                    </div>
                </div>
                <AboutJVRSection image={aboutJvdr} />
                <OurSolutionsSection />
                <OurTeamSection />
                <TestimonialSection />
                <LetsStartAConversation />
            </React.Fragment>
            
        )
    }
    
    render(){

        // const ldJson = {
        //     "@context": "https://schema.org",
        //     "@type": "InsuranceAgency",
        //     "name": "Van Der Riel And Associates General Practice",
        //     "image": "https://vanderrielpractice.co.za/static/media/about-jvdr@2x.35362f7a.jpg",
        //     "@id": "https://vanderrielpractice.co.za/static/media/about-jvdr@2x.35362f7a.jpg",
        //     "url": "https://vanderrielpractice.co.za",
        //     "telephone": "+27 74 143 3870",
        //     "address": {
        //       "@type": "PostalAddress",
        //       "streetAddress": "54 Oxford Street Durbanville",
        //       "addressLocality": "Cape Town",
        //       "postalCode": "7490",
        //       "addressCountry": "ZA"
        //     },
        //     "geo": {
        //       "@type": "GeoCoordinates",
        //       "latitude": -33.982334,
        //       "longitude": 18.598267
        //     },
        //     "openingHoursSpecification": {
        //       "@type": "OpeningHoursSpecification",
        //       "dayOfWeek": [
        //         "Tuesday",
        //         "Wednesday",
        //         "Monday",
        //         "Thursday",
        //         "Friday"
        //       ],
        //       "opens": "09:00",
        //       "closes": "17:00"
        //     } 
        // }

        const ldJson = {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "Organization",
                    "@id": "https://vanderrielpractice.co.za/#organization",
                    "name": "Van Der Riel And Associates General Practice",
                    "url": "https://vanderrielpractice.co.za",
                    "email": "jvanderrielpa3@oldmutual.com",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "54 Oxford Street Durbanville",
                        "addressLocality": "Cape Town",
                        "addressRegion": "Western Cape",
                        "postalCode": "7490",
                        "addressCountry": "South Africa"
                    },
                    "geo": {
                        "@type": "GeoCoordinates",
                        "latitude": -33.982334,
                        "longitude": 18.598267
                    },
                    "openingHoursSpecification": {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                        "Tuesday",
                        "Wednesday",
                        "Monday",
                        "Thursday",
                        "Friday"
                        ],
                        "opens": "09:00",
                        "closes": "17:00"
                    },
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://vanderrielpractice.co.za/static/media/vdr-logo.e0c8cd6f.svg"
                    },
                    "contactPoint": [
                        {
                            "@type": "ContactPoint",
                            "telephone": "+27 74 143 3870",
                            "contactType": "sales"
                        }
                    ],
                    "sameAs": [
                        "https://www.facebook.com/Van-der-Riel-Practice-348680295747374/",
                        "https://www.linkedin.com/in/juan-louis-van-der-riel-711108183/"
                    ]
                },
                {
                    "@type": "WebSite",
                    "@id": "https://vanderrielpractice.co.za/#website",
                    "url": "https://vanderrielpractice.co.za",
                    "name": "Van Der Riel And Associates General Practice",
                    "publisher": {
                        "@id": "https://vanderrielpractice.co.za/#organization"
                    },
                    "inLanguage": "en-ZA",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://vanderrielpractice.co.za/?s={search_term_string}",
                        "query-input": "required name=search_term_string"
                    }
                },
                {
                    "@type": "ImageObject",
                    "@id": "https://vanderrielpractice.co.za/#primaryImage",
                    "url": "https://vanderrielpractice.co.za/static/media/about-jvdr@2x.35362f7a.jpg",
                    "width": 940,
                    "height": 1400
                },
                {
                    "@type": "WebPage",
                    "@id": "https://vanderrielpractice.co.za/#webpage",
                    "url": "https://vanderrielpractice.co.za/",
                    "name": "Van Der Riel | Home Page",
                    "datePublished": "2020-9-14T17:09:05+02:00",
                    "dateModified": "2020-9-14T17:09:05+02:00",
                    "isPartOf": {
                        "@id": "https://vanderrielpractice.co.za/#website"
                    },
                    "primaryImageOfPage": {
                        "@id": "https://vanderrielpractice.co.za/#primaryImage"
                    },
                    "inLanguage": "en-ZA"
                }
            ]
        }
          
        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Home Page</title>
                    <meta name="description" content="We provide a holistic financial planning service that provides for all your major financial needs. We also assist in reviewing your financial plan on regular basis to ensure that you stay on track to reach your goals." />
                    <meta name="keywords" content="Financial, Adviser, Advisers, OldMutual, Risk, Estate, Planning, Agent, Government, Employees, Pension, Fund, GEPF, Legal, Consultants, Administrative, Staff, holistic" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}