import React from 'react'

export class Card3 extends React.Component {
    render(){
        return(
            <div className="vdr_card-3">
                <figure>
                    <img className="card-img-top" src={this.props.image} alt="" />
                </figure>
                <h4>{this.props.title}</h4>
                <p className="pb-4">{this.props.text}</p>
            </div>
        )
    }
}