import React from 'react'

export class BottomBar extends React.Component {
    render(){
        return(
            <div className="vdr_bottom-bar">
                <div className="container">
                    <div className="vdr_inner">
                        <small>Copyright ©2020 Van Der Riel Practice | All rights reserved | Created by <a href="https://www.optimalonline.co.za/" target="_blank" rel="noopener noreferrer">OPTIMAL ONLINE PTY</a></small>
                    </div>
                </div>
            </div>
        )
    }
}