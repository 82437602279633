import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading,
    ImageText
} from '../ui'
import buy_and_sell_agreements from '../../assets/img/buy-and-sell-agreements@2x.jpg'
import key_person_assurance from '../../assets/img/key-person-assurance@2x.jpg'
import { LetsStartAConversation } from '../Forms'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class BusinessAndKeyPersonRiskPlanning extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Business and Key-person Risk Planning"
                    img={ bannerDesktop }
                />
                <Heading title="Business and Key-person Risk Planning">

                    <p>Life, Earning Ability and Lifestyle Adjustment Assurance can also be used to help plan for the inevitable or unexpected when it comes to you, your business partners or your key staff at your business.</p>
                    
                </Heading>
                <ImageText image={buy_and_sell_agreements}>
                    <h3 className="vdr_green-text">Buy-And-Sell Agreements:</h3>

                    <p>These agreements are usually put in place between business partners or major shareholders in a private company. The agreement makes sure that if any of the business partners were to pass away, become permanently disabled (occupationally) or severely ill, that the other partners or shareholders can buy out the afflicted partners interests in the business (at fair value).</p>
                    <p>The necessary cover can then be taken out by the agreeing partners/shareholders to cover the costs for this. If correctly set up, there are no estate duty complications for such an agreement.</p>
                </ImageText>
                <ImageText image={key_person_assurance} order="switch">
                    <h3 className="vdr_green-text">Key-person Assurance:</h3>

                    <p>Your business can have severe operational and financial problems if a key staff member were to pass away, become permanently disabled or severely ill. Your business can take out the necessary cover on the life of the key person to assist with the financial costs of training or hiring a new staff member to take over those responsibilities. If correctly set up, there are no estate duty complications for such agreements and the business can also decide to claim tax deductions for contributing to such a plan.</p>
                    <p>There are also other agreements, cover and even investments that can be put in place for many business related events and scenarios.</p>
                    <p>It is therefore important to discuss this in full with a professional financial adviser in order to make sure the agreements are correctly set up and the correct cover put in place in line with your business’ financial means.</p>
                </ImageText>
                <LetsStartAConversation businessAndKeyPersonRiskPlanning />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/business-and-key-person-risk-planning"
            },
            "headline": "Business and Key-person Risk Planning",
            "description": "Life, Earning Ability and Lifestyle Adjustment Assurance can also be used to help plan for the inevitable or unexpected when it comes to you, your business partners or your key staff at your business.",
            "image": `https://vanderrielpractice.co.za${buy_and_sell_agreements}`,
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": `https://vanderrielpractice.co.za${imageObject}`
              }
            },
            "datePublished": "2020-9-30",
            "dateModified": "2020-9-30"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Business and Key-person Risk Planning</title>
                    <meta name="description" content="Life, Earning Ability and Lifestyle Adjustment Assurance can also be used to help plan for the inevitable or unexpected when it comes to you, your business partners or your key staff at your business." />
                    <meta name="keywords" content="Life, Earning, Lifestyle" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/business-and-key-person-risk-planning" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}