import React from 'react'
import { Nav } from '../Nav'
import { MetaTags } from '../Meta'
import { Banner } from '../Banners'
//temp placeholder images
import bannerDesktop from '../../assets/img/services-banner@2x.jpg'
import {
    Heading
} from '../ui'
import { LetsStartAConversation } from '../Forms/LetsStartAConversation'
import imageObject from '../../assets/img/Optimal-Online-Logo.jpg'

export class CreditLifeInsurance extends React.Component {

    renderContent = () => {
        return (
            <React.Fragment>
                <Banner 
                    title="Solutions" 
                    link="/solutions"
                    subtitle="Credit Life Insurance"
                    img={ bannerDesktop }
                />
                <Heading title="Credit Life Insurance">

                    <p><strong>What is Credit Life Insurance?</strong></p>

                    <p>Whether it’s a personal loan, a revolving loan, a credit card or a student loan, you are probably paying for credit life insurance as part of your debt repayment.</p>

                    <p>Credit life insurance is insurance that provides security should you be unable to repay your debt due to retrenchment, disability or death. The credit life insurance policy will pay the debt either in part or in full to the bank. It protects both you and your loved ones from the strain of having to service debt when life happens.</p>

                    <p>If you have any <strong>EXISTING</strong> Personal Loans or are in the process of applying for a personal Loan? Contact us today to save on your Credit Life Insurance!</p>

                    <p className="vdr_green-text"><strong>Save up to 15% of your total Monthly instalments.<br />
                    (Save up to R680 per month on Credit Life Insurance and enjoy more life cover and Benefits)</strong></p>

                    <p><strong>Below is an Example of how you can save:</strong></p>

                    <p>Clients are currently paying Plus minus R3.50 - R420 per R1000 taken on a Loan.</p>
                    <p>For an Example, a Personal Loan of R250 000 will Cost you R850 - R900 per month.<br />
                    (That is without the Interest you pay on insurance as well)</p>
                    <p>Save up to 10 - 20% on your total Repayment on your loan through just taking your own insurance rather than Credit Life Insurance.</p>
                    <p>It is easy and simple you contact us and we to the rest as well as all the Admin.</p>
                    
                </Heading>
                <LetsStartAConversation creditLifeInsurance />
            </React.Fragment>            
        )
    }
    
    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://vanderrielpractice.co.za/solutions/credit-life-insurance"
            },
            "headline": "Credit Life Insurance",
            "description": "Whether it’s a personal loan, a revolving loan, a credit card or a student loan, you are probably paying for credit life insurance as part of your debt repayment.",
            "image": "https://vanderrielpractice.co.za" + bannerDesktop,  
            "author": {
              "@type": "Organization",
              "name": "Van Der Riel Practice"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Optimal Online",
              "logo": {
                "@type": "ImageObject",
                "url": "https://vanderrielpractice.co.za" + imageObject
              }
            },
            "datePublished": "2020-10-13",
            "dateModified": "2020-10-13"
        }

        return(
            <React.Fragment>
                <MetaTags>
                    <title>Van Der Riel | Credit Life Insurance</title>
                    <meta name="description" content="Whether it’s a personal loan, a revolving loan, a credit card or a student loan, you are probably paying for credit life insurance as part of your debt repayment." />
                    <meta name="keywords" content="Credit Life Insurance, personal loan, debt repayment" />
                    <link rel="canonical" href="https://vanderrielpractice.co.za/solutions/credit-life-insurance" />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </MetaTags>
                <Nav>{this.renderContent()}</Nav>
            </React.Fragment>
        )
    }
}