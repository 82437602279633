import React from 'react'
import { Link } from 'react-router-dom'

export class Card2 extends React.Component {
    render(){
        return(
            <Link to={this.props.link}>
                <div className="card vdr_card-2">
                    <div className="vdr_img_cont">
                        <img src={this.props.image} alt={this.props.title} />
                    </div>
                    <h3>{this.props.title}</h3>
                    <p>{this.props.text}</p>
                    <div className="vdr_readmore-button">{this.props.linkText}</div>
                </div>
            </Link>
           
        )
    }
}