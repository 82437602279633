import React from 'react'
import fbIcon from '../../assets/img/facebook.svg'
import liIcon from '../../assets/img/linkedin.svg'
import oldMutualLogo from '../../assets/img/Old-Mutual-Logo-1.jpg'
import { OnScrollAnimation } from '../Animations/OnScrollAnimation'

export class Footer extends React.Component {

    render(){
        return(
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <OnScrollAnimation>
                                <figure>
                                    <img src={oldMutualLogo} alt="Old Mutual" width="150" style={{marginBottom: '15px'}} />
                                    <p>Old Mutual Life Assurance Company (SA) Limited is a Licenced Financial Service Provider (FSIP703)</p>
                                </figure>
                            </OnScrollAnimation>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <OnScrollAnimation>
                                <h3>About our business</h3>
                                <div className="hor_line"></div>
                                <p>We are a dedicated and professional financial planning practice that offers an all inclusive financial planning approach for both individuals and businesses.</p>
                            </OnScrollAnimation>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <OnScrollAnimation>
                                <h3>Contact Information</h3>
                                <div className="hor_line"></div>
                                <p> 
                                    <strong>Email:</strong> jvanderrielpa3@oldmutual.com <br />
                                    <strong>Contact:</strong> 074 143 3870 <br />
                                    <strong>Office Telephone Number:</strong> 021 970 3323 <br />
                                    <strong>Address:</strong> 54 Oxford Street, Durbanville <br />
                                    <strong>Open Hours:</strong> Mon - Fri 09:00 - 17:00
                                </p>
                            </OnScrollAnimation>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <OnScrollAnimation>
                                <h3>Follow us on</h3>
                                <div className="hor_line"></div>
                                <div>
                                    <a href="https://www.facebook.com/Van-der-Riel-Practice-348680295747374/" target="_blank" rel="noopener noreferrer">
                                        <img src={fbIcon} alt="Facebook" style={{marginRight: '30px'}} />
                                    </a>
                                    <a href="https://www.linkedin.com/in/juan-louis-van-der-riel-711108183/" target="_blank" rel="noopener noreferrer">
                                        <img src={liIcon} alt="Linkedin" />
                                    </a>
                                </div>
                            </OnScrollAnimation>
                        </div>
                        <div className="v_disclaimer col-md-12" style={{marginTop: '30px'}}>
                            <h3>DISCLAIMER</h3>
                            <p>Any and all comments and opinions in this website constitute comment and opinion only and does not in any way constitute personal financial advice. Please contact me should you need to meet and receive personal financial advice.</p>
                        </div>
                    </div>                        
                </div>
            </footer>
        )
    }
}